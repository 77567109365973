import { Route, Routes } from '@remix-run/react';
import React from 'react';

const HostVenue = React.lazy(() => import('./Venue'));
const HostAccess = React.lazy(() => import('./HostAccessScreen'));
const HostMinigame = React.lazy(() => import('./Minigame'));
const HostGamePack = React.lazy(() => import('./GamePack'));

// Easier to lazy import
// eslint-disable-next-line import/no-default-export
export default function Home(): JSX.Element {
  return (
    <Routes>
      <Route path={`access`} element={<HostAccess />} />
      <Route path={`venue/:vid/*`} element={<HostVenue />} />
      <Route path={`minigames/*`} element={<HostMinigame />} />
      <Route path={`gamepacks/*`} element={<HostGamePack />} />
    </Routes>
  );
}
